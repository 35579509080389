import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { FC } from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import LeftOverlay from "../components/left-overlay"
import { blockSerializer } from "../utils/sanity-helper"
import { dataset, projectId } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"

export const query = graphql`
  query {
    kontakt: sanityKontakt {
      bannerTitle
      addresses {
        _key
        name
        _rawInfo
      }
      _rawBody
      _rawMainImage
      mainImage {
        asset {
          fluid(maxWidth: 1152, maxHeight: 420) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }

    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const Kontakt: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge

  return (
    <Layout title="Kontakt" aar={aar} uge={uge}>
      <LeftOverlay title={props.data.kontakt.bannerTitle}>
        <Img
          backgroundColor
          fluid={getFluidImage(
            props.data.kontakt._rawMainImage,
            props.data.kontakt.mainImage.asset.fluid,
            1152,
            420
          )}
        />
      </LeftOverlay>

      <div className="grid grid-cols-2 gap-8 px-8 mt-16 text-center">
        {props.data.kontakt.addresses.map((address: any) => (
          <div key={address._key}>
            <Header>{address.name}</Header>
            <BlockContent
              blocks={address._rawInfo}
              serializers={blockSerializer()()}
              projectId={projectId}
              dataset={dataset}
            />
          </div>
        ))}
      </div>

      <BlockContent
        blocks={props.data.kontakt._rawBody}
        serializers={blockSerializer()(
          <div className="mt-4 text-center" />,
          "normal"
        )}
        projectId={projectId}
        dataset={dataset}
      />
    </Layout>
  )
}

export default Kontakt
